import React, { useState } from 'react';
import type { FunctionComponent } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Button, { SIZES } from 'common/components/Button';
import Checkbox from 'common/components/Forms/Checkbox';
import Modal, { ModalHeader, ModalContent, ModalFooter } from 'common/components/Modal';

import { getSkipChildMigration } from '../redux/selectors';
import { setSkipChildMigration } from '../redux/actions';

const renderInfoModal = (onCloseModal: () => void) => (
  <Modal className="view-info-modal-content" onDismiss={onCloseModal}>
    <ModalHeader title="Skip Child Migration" onDismiss={onCloseModal} />
    <ModalContent>
      <p>
        If "Skip Child Migration" is <strong>not checked</strong>, then moving an asset will also move all of
        its children.
      </p>
      <p>
        Children here are defined as "true" children, which includes:
        <ul>
          <li>
            Filtered views (SoQL derived views are always true children, JSON query derived views may or may
            not be true children depending on how they were created)
          </li>
          <li>Visualizations (both vizcan and "classic" viz)</li>
          <li>Data lenses</li>
        </ul>
      </p>
      <p>
        This means that the following are <strong>not</strong> considered children:
        <ul>
          <li>Stories</li>
          <li>Measures</li>
        </ul>
      </p>
      <hr />
      <p>
        Technically speaking, "children" here are defined as "<code>lenses</code> that have the same{' '}
        <code>blist_id</code>" that are not the default lens.
      </p>
      <hr />
      <p>
        It is <strong>strongly suggested</strong> that this be checked for most migrations unless you are
        migrating <em>all</em> assets from one domain to another.
      </p>
      <p>
        This should definitely be checked for Federation-related migrations from an Open Data domain to an
        Internal domain
      </p>
      <p>
        If you want to migrate a child asset but leave its parent alone, this should also be left checked.
      </p>
    </ModalContent>
    <ModalFooter>
      <Button onClick={onCloseModal}>Close</Button>
    </ModalFooter>
  </Modal>
);

const MigrateChildren: FunctionComponent = () => {
  const dispatch = useDispatch();
  const skipChildMigration = useSelector(getSkipChildMigration);

  const [showingInfoModal, setShowingInfoModal] = useState(false);

  return (
    <div className="skip-child-migration-container">
      <Checkbox
        id="skip-child-migration"
        label="Skip Child Migration"
        checked={skipChildMigration}
        name="skip_child_migration"
        onChange={(e) => dispatch(setSkipChildMigration(!skipChildMigration))}
      />
      <Button size={SIZES.X_SMALL} onClick={() => setShowingInfoModal(true)}>
        What's this?
      </Button>
      {showingInfoModal && renderInfoModal(() => setShowingInfoModal(false))}
    </div>
  );
};

export default MigrateChildren;
