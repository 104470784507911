import { action } from 'typesafe-actions';
import type { View } from 'common/types/view';

export enum ActionTypes {
  SetTargetDomainCname = 'SetTargetDomainCname',
  AddAssetUids = 'AddAssetUids',
  AssetLoaded = 'AssetLoaded',
  RemoveAsset = 'RemoveAsset',
  SetSkipChildMigration = 'SetMigrateChildAssets',
  SetSkipAllButSpecified = 'SetSkipAllButSpecified',
  PerformMigration = 'PerformMigration',
  MigrationFinished = 'MigrationFinished'
}

export const setTargetDomainCname = (targetDomainCname: string) =>
  action(ActionTypes.SetTargetDomainCname, { targetDomainCname });

export const addAssetUids = (assetUids: string[]) => action(ActionTypes.AddAssetUids, { assetUids });

export const assetLoaded = (payload: { uid: string; view?: View; error?: string; warning?: JSX.Element }) =>
  action(ActionTypes.AssetLoaded, payload);

export const removeAsset = (uid: string) => action(ActionTypes.RemoveAsset, { uid });

export const setSkipChildMigration = (skipChildMigration: boolean) =>
  action(ActionTypes.SetSkipChildMigration, { skipChildMigration });

export const setSkipAllButSpecified = (skipAllButSpecified: boolean) =>
  action(ActionTypes.SetSkipAllButSpecified, { skipAllButSpecified });

export const performMigration = () => action(ActionTypes.PerformMigration);

export const migrationFinished = (response?: unknown, requestId?: string) =>
  action(ActionTypes.MigrationFinished, { response, requestId });
