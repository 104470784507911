import React, { FunctionComponent, useState } from 'react';

import Button, { VARIANTS } from 'common/components/Button';
import AddAssetsModal from './AddAssetsModal';
import AssetList from './AssetList';

const Assets: FunctionComponent = () => {
  const [showingAddAssetsModal, setShowingAddAssetsModal] = useState(false);

  return (
    <div>
      <h3>Assets</h3>
      <p className="asset-list-warning-message">
        If no assets are added, then <strong>ALL ASSETS will be migrated.</strong>
      </p>
      <Button onClick={() => setShowingAddAssetsModal(true)}>Click Here To Add Assets</Button>
      <AssetList />
      {showingAddAssetsModal && <AddAssetsModal onDismissModal={() => setShowingAddAssetsModal(false)} />}
    </div>
  );
};

export default Assets;
