import React, { FunctionComponent, useState } from 'react';
import { useDispatch } from 'react-redux';

import Button, { VARIANTS } from 'common/components/Button';
import Modal, { ModalHeader, ModalContent, ModalFooter } from 'common/components/Modal';
import TextArea from 'common/components/Forms/TextArea';
import { addAssetUids } from '../../redux/actions';

interface Props {
  /** Function to call to close the modal */
  onDismissModal: () => void;
}

/**
 * Given a string, will split on whitespace, newlines, and commas and return an array of strings.
 * Will also filter out empty strings and duplicates.
 *
 * @param assetList User-entered string of UIDs
 */
const getUniqueAssetsFromCsv = (assetList: string) => [
  // we use a set to ensure unique values
  ...new Set(
    assetList
      // split on `,` ignoring whitespace on both sides
      .split(/\s*,\s*/g)

      // ... and just whitespace in general
      .flatMap((str) => str.split(/\s/))

      // filter out empty strings
      .filter((str) => str && str.trim().length > 0)
  )
];

/**
 * This modal takes in a CSV or newnline or space-separated list of UIDs and then
 * splits them out and sends them to the redux store
 */
const AddAssetsModal: FunctionComponent<Props> = ({ onDismissModal }) => {
  const dispatch = useDispatch();
  const [assetList, setAssetList] = useState('');

  const onButtonClick = () => {
    dispatch(addAssetUids(getUniqueAssetsFromCsv(assetList)));
    onDismissModal();
  };

  return (
    <Modal onDismiss={onDismissModal}>
      <ModalHeader onDismiss={onDismissModal}>
        <h4>Add Assets</h4>
      </ModalHeader>
      <ModalContent>
        <TextArea
          label="Paste in a comma, newline, or space separated list of asset UIDs (4x4s) and then click 'Add Assets' below"
          name="asset_list"
          value={assetList}
          onChange={(e) => setAssetList(e.target.value)}
        />
      </ModalContent>
      <ModalFooter>
        <Button variant={VARIANTS.PRIMARY} onClick={onButtonClick}>
          Add Assets
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default AddAssetsModal;
