import React, { useState } from 'react';
import type { FunctionComponent } from 'react';
import { useSelector } from 'react-redux';

import Button, { VARIANTS } from 'common/components/Button';

import { getMigrationResponse } from '../../redux/selectors';
import ConfirmMigrationModal from './ConfimMigrationModal';

const ConfirmMigrationButton: FunctionComponent = () => {
  const [showingConfirmationModal, setShowingConfirmationModal] = useState(false);

  return (
    <>
      <div className="migrate-domain-button-container">
        <Button variant={VARIANTS.PRIMARY} onClick={() => setShowingConfirmationModal(true)}>
          Migrate Assets
        </Button>
        <p>(a confirmation modal will appear)</p>
      </div>
      {showingConfirmationModal && (
        <ConfirmMigrationModal onCancel={() => setShowingConfirmationModal(false)} />
      )}
    </>
  );
};

export default ConfirmMigrationButton;
