import React from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';

import FederationsApi from 'common/core/federations';

import createStore from './redux/store';
import MigrateAssets from './components/MigrateAssets';

import './index.scss';

(async () => {
  const federations = await FederationsApi.getFederationsForCurrentDomain();

  const targetElement = document.getElementById('main');

  if (!federations) {
    render(<div className="spinner-default spinner-large" />, targetElement);
  } else {
    render(
      <Provider store={createStore(federations)}>
        <h1>Migrate Assets</h1>
        <h2>This UI can be used to migrate assets from one domain to another.</h2>
        <p>
          For more information about what this page is doing, see{' '}
          <a
            href="https://socrata.atlassian.net/wiki/spaces/PD/pages/354189559/API+Docs+Data+Movement+SuperAdmin+Tool"
            target="_blank"
          >
            API Docs: Data Movement SuperAdmin Tool
          </a>
        </p>
        <MigrateAssets />
      </Provider>,
      targetElement
    );
  }
})();
