import React, { useState } from 'react';
import type { FunctionComponent } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Button, { SIZES } from 'common/components/Button';
import Checkbox from 'common/components/Forms/Checkbox';
import Modal, { ModalHeader, ModalContent, ModalFooter } from 'common/components/Modal';

import { getSkipAllButSpecified } from '../redux/selectors';
import { setSkipAllButSpecified } from '../redux/actions';

const renderInfoModal = (onCloseModal: () => void) => (
  <Modal className="view-info-modal-content" onDismiss={onCloseModal}>
    <ModalHeader title="Skip All But Specified" onDismiss={onCloseModal} />
    <ModalContent>
      <p>
        If "Skip All But Specified" is <strong>not checked</strong>, then moving an asset may move its parent
        and/or children.
      </p>
      <p>
        If "Skip All But Specified" is <strong>checked</strong>, then only the given uids and their working or
        published copies will be migrated.
      </p>
    </ModalContent>
    <ModalFooter>
      <Button onClick={onCloseModal}>Close</Button>
    </ModalFooter>
  </Modal>
);

const SkipAllButSpecified: FunctionComponent = () => {
  const dispatch = useDispatch();
  const skipAllButSpecified = useSelector(getSkipAllButSpecified);

  const [showingInfoModal, setShowingInfoModal] = useState(false);

  return (
    <div className="skip-all-but-specified-container">
      <Checkbox
        id="skip-all-but-specified"
        label="Skip All But Specified"
        checked={skipAllButSpecified}
        name="skip_all_but_specified"
        onChange={(e) => dispatch(setSkipAllButSpecified(!skipAllButSpecified))}
      />
      <Button size={SIZES.X_SMALL} onClick={() => setShowingInfoModal(true)}>
        What's this?
      </Button>
      {showingInfoModal && renderInfoModal(() => setShowingInfoModal(false))}
    </div>
  );
};

export default SkipAllButSpecified;
