import { createStore, applyMiddleware, Store, compose } from 'redux';
import createSagaMiddleware from 'redux-saga';

import type { Federation } from '@socrata/core-federations-api';

import { State } from '../types';
import reducer, { Actions } from './reducer';
import sagas from './sagas';

export type StoreType = Store<State, Actions>;

export default (federations: Federation[]): StoreType => {
  const initialState: State = {
    federations,
    currentDomainCname: window.socrata.domain!,
    assets: {},
    skipChildMigration: true,
    skipAllButSpecified: false,
    doingMigration: false
  };

  // use the redux devtool's composeEnhancers to keep them around
  const composeEnhancers =
    (window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ &&
      window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({ name: 'Migrate Domain' })) ||
    compose;

  const sagaMiddleware = createSagaMiddleware();

  const store = createStore<State, Actions, null, any>(
    reducer,
    initialState,
    composeEnhancers(applyMiddleware(sagaMiddleware))
  );

  sagaMiddleware.run(sagas);

  return store;
};
