import React from 'react';

import { View, ViewType, DisplayType } from 'common/types/view';
import { isSoQLBased } from 'common/views/view_types';

/**
 * This list IS supported by the data movement API
 * This is defined in core by the "migratableDisplayTypes" block in "domains_map.xml"
 */
const supportedDisplayTypesByDataMovementApi: DisplayType[] = [
  DisplayType.Measure,
  DisplayType.Draft,
  DisplayType.Href,
  DisplayType.Table,
  DisplayType.Blob,
  DisplayType.VisualizationCanvasChart,
  DisplayType.VisualizationCanvasTable,
  DisplayType.VisualizationCanvasMap,
  DisplayType.Story
];

// For more info, see https://socrata.atlassian.net/wiki/spaces/PD/pages/328859734/Supported+Asset+Types
const unsupportedByDataFederation: { viewType: ViewType; displayType: DisplayType }[] = [
  {
    viewType: ViewType.Tabular,
    displayType: DisplayType.Chart
  },
  {
    viewType: ViewType.Tabular,
    displayType: DisplayType.Calendar
  },
  {
    viewType: ViewType.Tabular,
    displayType: DisplayType.Form
  }
];

/**
 * Given a view, will return a warning to show for it in the UI.
 * Will return `undefined` if there is no warning for the given view.
 */
export default (view: View): JSX.Element | undefined => {
  if (!supportedDisplayTypesByDataMovementApi.some((displayType) => view.displayType === displayType)) {
    return (
      <span>
        This asset is <strong>NOT</strong> supported by the data movement API.
        <br />
        This will be marked as a "broken" if you attempt to move it.
      </span>
    );
  }

  if (isSoQLBased(view)) {
    return (
      <span>
        This is a SoQL-based view!
        <br />
        If this is migrated to a data federation source domain, it will still be available on the target
        domain but only via API and it will not be discoverable.
      </span>
    );
  }

  if (
    unsupportedByDataFederation.some(
      (viewAndDisplayType) =>
        view.viewType === viewAndDisplayType.viewType && view.displayType === viewAndDisplayType.displayType
    )
  ) {
    return (
      <span>
        This asset is not supported by data federation.
        <br />
        If it is migrated to a data federation source domain, it will not federate back to the target domain.
      </span>
    );
  }

  if (view.provenance === 'community') {
    return (
      <span>
        This is a "community" asset.
        <br />
        It may not make sense to migrate it to a data federation source domain.
      </span>
    );
  }

  return;
};
