import React from 'react';
import type { FunctionComponent } from 'react';
import AceEditor from 'react-ace';
import 'ace-builds/src-noconflict/theme-monokai';
import 'ace-builds/src-noconflict/mode-json';
import 'ace-builds/src-noconflict/ext-language_tools';

import type { View } from 'common/types/view';
import Button from 'common/components/Button';
import Modal, { ModalHeader, ModalContent, ModalFooter } from 'common/components/Modal';

interface Props {
  view: View;
  onClose: () => void;
}

const ViewInfoModal: FunctionComponent<Props> = ({ view, onClose }) => {
  return (
    <Modal onDismiss={onClose} className="view-info-modal-content">
      <ModalHeader onDismiss={onClose}>
        <h4>
          Info for {view.name} ({view.id})
        </h4>
      </ModalHeader>
      <ModalContent>
        <AceEditor
          readOnly
          theme="monokai"
          mode="json"
          width="100%"
          height="700px"
          fontSize={20}
          tabSize={2}
          showGutter={true}
          editorProps={{ $blockScrolling: true }}
          enableBasicAutocompletion={true}
          enableLiveAutocompletion={true}
          defaultValue={JSON.stringify(view, null, 2)}
        />
      </ModalContent>
      <ModalFooter>
        <Button onClick={onClose}>Close</Button>
      </ModalFooter>
    </Modal>
  );
};

export default ViewInfoModal;
