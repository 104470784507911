import React, { useState } from 'react';
import type { FunctionComponent } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import type { View } from 'common/types/view';
import ResultsTable from 'common/components/ResultsTable';
import Button, { SIZES, VARIANTS } from 'common/components/Button';

import { Asset } from '../../types';
import { getAssets } from '../../redux/selectors';
import { removeAsset } from '../../redux/actions';
import ViewInfoModal from './ViewInfoModal';

/**
 * Get the status message to show for an asset
 * @param asset Asset to get status for
 */
const getAssetStatus = (asset: Asset): JSX.Element | string => {
  if (!asset.error && !asset.view) {
    // still loading the asset, show a spinner
    return <div className="spinner spinner-default" />;
  } else if (asset.error) {
    // there was an error!
    return <>❌ {asset.error}</>;
  } else if (asset.warning) {
    return <>⚠️ {asset.warning}</>;
  } else {
    // it's good to go
    return '✅ Good to go!';
  }
};

/**
 * Get the action buttons for an asset
 * @param uid UID of asset
 * @param asset Asset that contains view, error, etc.
 * @param setShowingDetailsModal Function to call to show the details modal for the asset
 * @param dispatch Redux dispatch function
 */
const getActions = (
  uid: string,
  asset: Asset,
  setShowingDetailsModal: React.Dispatch<React.SetStateAction<View | undefined>>,
  dispatch: ReturnType<typeof useDispatch>
): JSX.Element => (
  <>
    {asset.view && (
      <Button
        size={SIZES.X_SMALL}
        className="asset-list-view-details-button"
        onClick={() => setShowingDetailsModal(asset.view)}
      >
        View Details
      </Button>
    )}
    <Button size={SIZES.X_SMALL} variant={VARIANTS.ERROR} onClick={() => dispatch(removeAsset(uid))}>
      Remove
    </Button>
  </>
);

const AssetList: FunctionComponent = () => {
  const dispatch = useDispatch();
  const assets = useSelector(getAssets);

  // this is used to show a "ViewInfoModal" for a specific asset
  const [showingDetailsModal, setShowingDetailsModal] = useState<View | undefined>();

  return (
    <>
      <ResultsTable
        data={Object.keys(assets).map((uid) => ({
          uid
        }))}
        noResultsMessage="Add assets with the 'Click Here To Add Assets' button"
      >
        <ResultsTable.Column header="Asset" dataIndex="uid">
          {(uid: string) => (
            <a href={`https://${window.socrata.domain}/d/${uid}`} target="_blank" rel="noopener noreferrer">
              {uid}
            </a>
          )}
        </ResultsTable.Column>
        <ResultsTable.Column header="Name" dataIndex="uid">
          {(uid: string) => assets[uid].view?.name}
        </ResultsTable.Column>
        <ResultsTable.Column header="Status" dataIndex="uid">
          {(uid: string) => getAssetStatus(assets[uid])}
        </ResultsTable.Column>
        <ResultsTable.Column header="Actions" dataIndex="uid">
          {(uid: string) => getActions(uid, assets[uid], setShowingDetailsModal, dispatch)}
        </ResultsTable.Column>
      </ResultsTable>
      {showingDetailsModal && (
        <ViewInfoModal view={showingDetailsModal} onClose={() => setShowingDetailsModal(undefined)} />
      )}
    </>
  );
};

export default AssetList;
