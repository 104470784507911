import React from 'react';
import type { FunctionComponent } from 'react';
import { useSelector } from 'react-redux';
import AceEditor from 'react-ace';
import 'ace-builds/src-noconflict/theme-monokai';
import 'ace-builds/src-noconflict/mode-json';
import 'ace-builds/src-noconflict/ext-language_tools';

import Button, { VARIANTS } from 'common/components/Button';
import Modal, { ModalHeader, ModalContent, ModalFooter } from 'common/components/Modal';
import { reload as windowLocationReload } from 'common/window_location';

import { getMigrationResponse } from '../redux/selectors';

const MigrationFinishedModal: FunctionComponent = () => {
  const migrationResponse = useSelector(getMigrationResponse);

  if (!migrationResponse.migrationResponse && !migrationResponse.migrationResponseRequestId) {
    return null;
  }

  const onClose = () => windowLocationReload();

  return (
    <Modal onDismiss={onClose} className="view-info-modal-content">
      <ModalHeader title="Migration Finished!" onDismiss={onClose} />
      <ModalContent>
        <strong>Response:</strong>
        <AceEditor
          readOnly
          theme="monokai"
          mode="json"
          width="100%"
          height="700px"
          fontSize={20}
          tabSize={2}
          showGutter={true}
          editorProps={{ $blockScrolling: true }}
          enableBasicAutocompletion={true}
          enableLiveAutocompletion={true}
          defaultValue={JSON.stringify(migrationResponse.migrationResponse, null, 2)}
        />
        {migrationResponse.migrationResponseRequestId && (
          <>
            <strong>Request ID: </strong>
            {migrationResponse.migrationResponseRequestId}
            <div>
              Give this to{' '}
              <a href="https://di-tylertech.slack.com/archives/CTSMT5295">the #access-control channel</a> when
              asking for help.
            </div>
          </>
        )}
      </ModalContent>
      <ModalFooter>
        <Button variant={VARIANTS.PRIMARY} onClick={onClose}>
          Close
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default MigrationFinishedModal;
