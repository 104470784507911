import React, { FunctionComponent } from 'react';

import Form from 'common/components/Forms/Form';

import Domains from './Domains';
import Assets from './Assets';
import MigrateChildren from './MigrateChildren';
import SkipAllButSpecified from './SkipAllButSpecified';
import ConfirmMigrationButton from './ConfirmMigrationButton';
import MigrationFinishedModal from './MigrationFinishedModal';

const MigrateDomain: FunctionComponent = () => {
  return (
    <Form>
      <Domains />
      <br />
      <Assets />
      <br />
      <MigrateChildren />
      <br />
      <SkipAllButSpecified />
      <br />
      <ConfirmMigrationButton />
      <MigrationFinishedModal />
    </Form>
  );
};

export default MigrateDomain;
