import React, { FunctionComponent, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { getCurrentDomainCname, getTargetDomainCname, getFederations } from '../redux/selectors';
import { setTargetDomainCname } from '../redux/actions';
import Input from 'common/components/Forms/Input';
import Radio from 'common/components/Forms/Radio';
import Dropdown, { DropdownOption } from 'common/components/Forms/Dropdown';

const Domains: FunctionComponent = () => {
  const dispatch = useDispatch();
  const federations = useSelector(getFederations);
  const sourceDomainCname = useSelector(getCurrentDomainCname);
  const targetDomainCname = useSelector(getTargetDomainCname) || '';

  // we show a list of federation relationships to migrate to
  // since that's the primary use-case of this tool,
  // but users can still choose to enter a domain
  const [choosingFromDropdown, setChoosingFromDropdown] = useState(true);

  // just a list of federations
  const dropdownOptions: DropdownOption<string>[] = federations.map((federation) => ({
    title: `${federation.targetDomainCName!} (${federation.isIncomingFederation! ? 'incoming' : 'outgoing'} ${
      federation.scope
    } ${federation.type} federation)`,
    value: federation.targetDomainCName!
  }));

  return (
    <>
      <h3>Domains</h3>
      <div>
        <Input name="source_domain" label="Source Domain (this domain)" value={sourceDomainCname} disabled />
      </div>
      <div className="target-domain-chooser">
        {choosingFromDropdown ? (
          <Dropdown
            labelContent="Target Domain"
            dropdownLabel="target_domain_dropdown"
            value={targetDomainCname}
            options={dropdownOptions}
            handleSelection={(e) => dispatch(setTargetDomainCname(e.value.valueOf() as string))}
          />
        ) : (
          <Input
            name="target_domain"
            label="Target Domain (NO 'https://' or trailing '/')"
            value={targetDomainCname}
            onChange={(e) => dispatch(setTargetDomainCname(e.target.value))}
          />
        )}
        <div className="choose-domain-radio-buttons">
          <Radio
            label="Choose target domain from federations"
            name="choose_domain_from_federation"
            checked={choosingFromDropdown}
            onChange={(e) => (e.target.checked ? setChoosingFromDropdown(true) : null)}
          />
          <Radio
            label="Enter a target domain name"
            name="enter_domain_name"
            checked={!choosingFromDropdown}
            onChange={(e) => (e.target.checked ? setChoosingFromDropdown(false) : null)}
          />
        </div>
      </div>
    </>
  );
};

export default Domains;
