import { State } from '../types';

export const getFederations = (state: State) => state.federations;

export const getCurrentDomainCname = (state: State) => state.currentDomainCname;

export const getTargetDomainCname = (state: State) => state.targetDomainCname;

export const getAssets = (state: State) => state.assets;

export const getSkipChildMigration = (state: State) => state.skipChildMigration;

export const getSkipAllButSpecified = (state: State) => state.skipAllButSpecified;

export const getDoingMigration = (state: State) => state.doingMigration;

export const getMigrationResponse = (state: State) => ({
  migrationResponse: state.migrationResponse,
  migrationResponseRequestId: state.migrationResponseRequestId
});
